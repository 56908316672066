import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { captureEvent } from "ui/lib/captureEvent";
import { isDev } from "ui/lib/helpers";
import { updateUTMParams } from "ui/lib/misc";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { accessVariant } from "web/components/text-node";
import { useExperiment } from "web/state-containers/experiment";
import { useModals } from "./modals/container";

const MotionLink = motion.create(Link);

interface ActionProps
  extends Omit<React.ComponentPropsWithoutRef<typeof MotionLink>, "href"> {
  at: string;
  defaultClasses?: string;
  className?: string;
  event?: {
    category?: string;
    action?: string;
    label?: string;
    [key: string]: any;
  };
}

export const ReferralAction = React.memo<ActionProps>(
  ({
    at,
    defaultClasses = "text-lg rounded-full cta",
    className,
    event,
    ...props
  }) => {
    const { query } = useRouter();
    const isMobile = useIsMobile();
    const { variant } = useExperiment();
    const { toggleShowQRModal } = useModals();
    const accessedVariant = accessVariant(at, variant as string);

    const { deepLink } = useDeeplink();

    const sendEvent = () => {
      captureEvent({
        category: at,
        action: "CTA Clicked",
        label: accessedVariant.label,
        guest_pass_type: query.promoCode ?? "none",
        ...event,
      });
    };

    const handleClick = (
      e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
    ) => {
      sendEvent();
      if (!isMobile) {
        e.preventDefault();
        toggleShowQRModal();
      }
      props.onClick?.(e as React.MouseEvent<HTMLAnchorElement>);
    };

    if (!isMobile) {
      return (
        <button
          onClick={handleClick}
          className={cn(
            "relative inline-block cursor-pointer",
            defaultClasses,
            className
          )}
        >
          {accessedVariant.label}
        </button>
      );
    }

    return (
      <MotionLink
        {...props}
        href={deepLink}
        target="_self"
        rel="noreferrer"
        onClick={handleClick}
        aria-label={accessedVariant.label}
        className={cn(
          "relative inline-block cursor-pointer",
          defaultClasses,
          className
        )}
        whileHover={{ scale: 1.025 }}
        whileTap={{ scale: 0.975 }}
      >
        {accessedVariant.label}
      </MotionLink>
    );
  }
);

ReferralAction.displayName = "ReferrerAction";

const useDeeplink = () => {
  const isMobile = useIsMobile();
  const { query } = useRouter();

  const deepLink = useMemo(() => {
    const deepLink = new URL(
      "https://ladder.page.link?ibi=com.ladder.bootcamp&isi=1502936453&ius=ladderteams"
    );

    // Set a default image for link preview
    deepLink.searchParams.set(
      "si",
      "https://ladderteams.imgix.net/website/quiz/firebaseurlpreview_402x180.jpg"
    );

    const baseDeepLink = new URL(
      `https://${isDev ? "dev." : ""}joinladder.com`
    );

    // Add analytics ID if available
    // @ts-ignore
    // const analyticsID = window?.analytics?.user?.()?.anonymousId?.();
    // if (analyticsID) {
    //   baseDeepLink.searchParams.set("analyticsID", analyticsID);
    // }

    // Update URL with additional parameters (UTM, etc.)
    const link = updateUTMParams(baseDeepLink);

    // Adjust UTM content for non-mobile devices
    if (!isMobile) {
      const content = link.searchParams.get("utm_content");
      if (content) {
        link.searchParams.set("utm_content", `${content}-desktop`);
      } else {
        link.searchParams.set("utm_content", "desktop");
      }
    }

    // Set the final link
    deepLink.searchParams.set("link", link.toString());

    return deepLink;
  }, [isMobile]);

  return { deepLink };
};
